<template>
    <div class="container my-24 my-lg-40">
      <div class="row mb-24">
        <header class="col">
          <h2>Согласие на обработку персональных данных</h2>
        </header>
      </div>

      <section class="row">
        <article
          class="col-12"
        >
          <v-card class="p-16 py-md-32 p-lg-32">
            <p>
              Вы приступили к выполнеию третьей части теста:  <b>«Анализ идей»</b>.
              Вам будут предложены тексты с описанием той или иной ситуации,
              а затем вопросы к ним. Всего <b>9 заданий</b>, общее время
              на их выполнение — <b>25 минут</b>.
            </p>

            <p>
              При решении заданий этой части помните о том, что среди предложенных
              вариантов ответа всегда есть <b>один ответ</b>, наболее подходящий
              в описанной ситуации.
            </p>

            <p>
              <b>Нажмите кнопку “Приступить к тестированию”.</b>
            </p>

            <v-button class="mt-24">Приступить к тестированию</v-button>
          </v-card>
        </article>
      </section>
    </div>
</template>

<script>
import VCard from '../../../components/base/VCard/VCard'
import VButton from '../../../components/base/VButton/VButton'

export default {
  name: 'SInstruction',

  components: {
    VButton,
    VCard
  }
}
</script>
